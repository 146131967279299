import { NgClass } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputControlDirective } from './directive/input-control.directive';

@Component({
  selector: 'coach-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  imports: [FormsModule, ReactiveFormsModule, NgClass],
  standalone: true,
  hostDirectives: [InputControlDirective],
})
export class InputComponent {
  inputControl = inject(InputControlDirective);

  label = input<string>('');
  placeholder = input<string>('');
  errorMessage = input<string>('');
  disable = input<boolean>(false);
  size = input<'small' | 'medium'>('medium');
}
