<div class="title-container" *transloco="let t; read: 'players'">
  <h1 class="heading-title">{{ t('title') }}</h1>
  <div class="heading-buttons">
    <coach-button (click)="playerService.refreshPlayerList()" icon="refresh" size="medium" variant="outlined" />
    <coach-button (click)="openInviteDialog()" size="medium">{{ t('invite') }}</coach-button>
  </div>
</div>
<div class="search-bar">
  <coach-search
    *transloco="let t; read: 'players'"
    type="players"
    [placeholder]="t('placeholderText')"
    [filters]="false"
    [(query)]="filterQuery" />
</div>
<nav class="navigation">
  <coach-tabs [(tabs)]="state().tabs" orientation="horizontal-row" [bold]="true" [(activeTabId)]="currentTabId" />
</nav>
@if (state().data; as p) {
  @if (p.status === 'loading') {
    <coach-spinner text="Loading players..." />
  }
  @if (p.status === 'loaded') {
    @if (state().isCurrentTabEmpty) {
      @if (filterQuery().length) {
        <coach-empty-state
          *transloco="let t"
          [title]="t('players.noPlayersFound')"
          [description]="t('players.adjustSearch')"
          [icon]="'search_error'" />
      } @else {
        <div class="invite-player-container" *transloco="let t">
          <coach-empty-state
            [title]="t('invite.onboarding.title')"
            [description]="t('invite.onboarding.description')"
            [icon]="'addplayer_outlined'" />
          <coach-button (click)="openInviteDialog()" size="medium">{{ t('players.invite') }}</coach-button>
        </div>
      }
    } @else {
      <table cdk-table [dataSource]="state().source" *transloco="let t">
        <ng-container cdkColumnDef="name">
          <th cdk-header-cell *cdkHeaderCellDef>{{ t('players.name') }}</th>
          <td cdk-cell *cdkCellDef="let player">
            <a class="player" [routerLink]="player.user_id ? ['/player', player.user_id, 'profile'] : null">
              @if (state().onLinkedTab) {
                <coach-avatar [name]="player.name" />
              }
              <div class="stack">
                <span class="name">{{ player.name }}</span>
                @if (!state().onLinkedTab) {
                  @if (player.email; as email) {
                    <span class="email">{{ email }}</span>
                  }
                  @if (device.isMobile()) {
                    <ng-template *ngTemplateOutlet="badgesTemplate; context: { player, t }" />
                  }
                }
              </div>
            </a>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="status">
          <th cdk-header-cell *cdkHeaderCellDef>{{ t('state.status') }}</th>
          <td cdk-cell *cdkCellDef="let player">
            <ng-template *ngTemplateOutlet="badgesTemplate; context: { player, t }" />
          </td>
        </ng-container>
        <ng-container cdkColumnDef="activity">
          <th cdk-header-cell *cdkHeaderCellDef>{{ t('players.activity') }}</th>
          <td cdk-cell *cdkCellDef="let player; let i = index">
            @if (i === 0) {
              <coach-active-badge [animated]="true" />
            } @else {
              <span class="activity">{{ player.last_activity ?? t('players.idle') }}</span>
            }
          </td>
        </ng-container>
        <ng-container cdkColumnDef="expiration_date">
          <th cdk-header-cell *cdkHeaderCellDef>{{ t('state.expires') }}</th>
          <td cdk-cell *cdkCellDef="let player">
            <span class="date">
              {{ player.expiration_date | date: 'dd MMM YYYY HH:mm' }}
            </span>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="actions">
          <th cdk-header-cell *cdkHeaderCellDef>{{ t('interaction.actions') }}</th>
          <td cdk-cell *cdkCellDef="let player">
            <div class="actions">
              <coach-button
                [disabled]="!player.expired"
                (click)="openInviteDialog(player.name, player.email, true)"
                variant="text"
                type="primary"
                icon="refresh"
                size="large" />
              <coach-button variant="text" type="negative" icon="trashcan_detailed" size="large" />
            </div>
          </td>
        </ng-container>
        <tr cdk-header-row *cdkHeaderRowDef="state().displayedColumns"></tr>
        <tr cdk-row *cdkRowDef="let row; columns: state().displayedColumns"></tr>
      </table>
    }
  }
  @if (p.status === 'error') {
    <coach-empty-state
      *transloco="let t"
      [title]="t('error.generic.title')"
      [description]="t('error.generic.description')"
      [icon]="'search_error'" />
  }
}

<ng-template #badgesTemplate let-player="player" let-t="t">
  <div class="badges">
    @if (player.expired) {
      <coach-badge icon="invite_expired_outlined" [text]="t('invite.expired')" type="negative" />
    } @else {
      <coach-badge icon="mail" [text]="t('invite.pending')" type="positive" />
    }
  </div>
</ng-template>
