import { DialogRef } from '@angular/cdk/dialog';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { ApiService } from '@shared/services/api.service';
import { UtilityService } from '@shared/services/utility.service';
import { viewModelFrom } from '@shared/types/api.types';
import { CloseDialogStatus } from '@shared/types/generic.types';
import { InviteData } from '@shared/types/user.types';
import { ButtonComponent } from '@shared/ui/button/button.component';
import { DialogComponent } from '@shared/ui/dialog/dialog.component';
import { InputComponent } from '@shared/ui/input/input.component';

@Component({
  selector: 'coach-invite-dialog',
  standalone: true,
  imports: [DialogComponent, InputComponent, ButtonComponent, TranslocoDirective, ReactiveFormsModule],
  templateUrl: './invite-dialog.component.html',
  styleUrl: './invite-dialog.component.scss',
})
export class InviteDialogComponent implements OnInit {
  #api = inject(ApiService);
  dialogRef = inject(DialogRef<CloseDialogStatus>);
  dialogData: { email: string; name: string; isResend: boolean } = this.dialogRef.config.data;
  teamId = toSignal(inject(UtilityService).teamId$);
  isTryingToInvite = signal<boolean>(false);

  form = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    fullName: new FormControl('', Validators.required),
  });

  inviteData = computed<InviteData>(() => ({
    email: this.form.get('email')?.value || 'Unknown',
    name: this.form.get('fullName')?.value || 'Unknown',
    role: 'player',
    team_id: this.teamId() ?? null,
  }));

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  isFormValid() {
    return this.form.valid;
  }

  sendInvite() {
    const request =
      this.dialogData.isResend ?
        this.#api.put<InviteData, null>(`users/invites/resend`, this.inviteData())
      : this.#api.post<InviteData, string>(`users/invites`, this.inviteData());

    viewModelFrom(request).subscribe(response => {
      switch (response.status) {
        case 'loading':
          this.isTryingToInvite.set(true);
          break;
        case 'loaded':
          this.dialogRef.close('success');
          break;
        case 'error':
          this.dialogRef.close('error');
          break;
      }
    });
  }
}
