import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Observable, tap } from 'rxjs';

// Example of a feature flag object from user profile
export type FeatureFlagResponse = {
    drills: boolean;
    admin: boolean;
    liveView: boolean;
    swingCapture: boolean;
}

type _FeatureFlagKey = keyof FeatureFlagResponse;
export type FeatureFlagKey = {
    [key in _FeatureFlagKey]: key;
} [_FeatureFlagKey];


@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  http = inject(HttpClient);
  features = signal<Record<string, boolean>>({});

  getFeatureFlags(): Observable<FeatureFlagResponse> {
    return this.http.get<FeatureFlagResponse>('/assets/mock/flags.json').pipe(
      tap(features => {
        this.features.set(features);
      })
    );
  }

  getFeature(feature: FeatureFlagKey): boolean {
    return this.features()[feature] ?? false;
  }
}
