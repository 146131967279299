<div class="bar-container">
  <div class="bar-menu">
    @if (auth.isOnlyRoles(['player'])) {
      <img class="coach-logo" src="assets/images/coach-logo.svg" alt="Coach logo" />
    } @else if (device.isTablet()) {
      <coach-button (click)="device.setSideNavState(true)" variant="text" icon="menu" />
    }
  </div>
  <div class="bar-content">
    <coach-active-session-button />
    <coach-profile-dropdown />
  </div>
</div>
