import { Component, inject } from '@angular/core';
import { DeviceService } from '@shared/services/device.service';
import { ActiveSessionButtonComponent } from '@shared/ui/active-session-button/active-session-button.component';
import { ButtonComponent } from '@shared/ui/button/button.component';
import { ProfileDropdownComponent } from '@shared/ui/profile-dropdown/profile-dropdown.component';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'coach-top-bar',
  standalone: true,
  imports: [ButtonComponent, ActiveSessionButtonComponent, ProfileDropdownComponent],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss',
})
export class TopBarComponent {
  auth = inject(AuthService);
  device = inject(DeviceService);
}
