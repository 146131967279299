<nav class="sidebar elevation-1" role="navigation">
  @if (device.isTablet()) {
    <div class="close-container">
      <coach-button (click)="device.setSideNavState(false)" variant="text" type="white" icon="x" />
    </div>
    <coach-tabs [tabs]="tabs" orientation="vertical-row" (onClick)="tabClicked()" />
    <img class="logo logo-text" src="assets/images/coach-logo-primary.svg" alt="Coach logo" />
  } @else {
    <coach-tabs [tabs]="tabs" orientation="vertical-column" />
    <img class="logo logo-icon" src="assets/images/coach-logo.svg" alt="Coach logo" />
  }
</nav>
