import { Component, input } from '@angular/core';
import { BadgeComponent } from '../badge/badge.component';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'coach-active-session-button',
  standalone: true,
  imports: [ButtonComponent, BadgeComponent],
  templateUrl: './active-session-button.component.html',
  styleUrl: './active-session-button.component.scss',
})
export class ActiveSessionButtonComponent {
  count = input<number>(0);
}
