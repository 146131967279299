<div class="login-container" *transloco="let t">
  <div class="login">
    <img class="logo" src="assets/images/coach-logo-horizontal-primary.svg" alt="logo" />
    <div class="form">
      <div class="invite-container">
        <div>
          <h1 class="title-1">{{ t('coachInvite.title') }}</h1>
          <h3 class="title-3">{{ t('coachInvite.header') }}</h3>
        </div>
        <p>{{ t('coachInvite.info') }}</p>
        <div class="footer">
          <coach-button
            (click)="logIn()"
            [text]="t('coachInvite.start')"
            [icon]="'arrow_right'"
            [iconPosition]="'right'" />
        </div>
      </div>
    </div>
    <coach-language-picker class="picker" [menuConfig]="{ anchorPoint: ['top', 'left'], animation: 'from-bottom' }" />
  </div>
  <div class="image-container">
    <img class="image" src="assets/images/login-image-1.jpg" alt="login-image" />
    <img class="image" src="assets/images/login-image-2.jpg" alt="login-image" />
  </div>
</div>
