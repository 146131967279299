import { NgClass, NgStyle } from '@angular/common';
import { Component, effect, inject, signal } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { NavigationComponent } from '@core/navigation/navigation.component';
import { TopBarComponent } from '@core/top-bar/top-bar.component';
import { DeviceService } from '@shared/services/device.service';
import { AuthService } from './auth/services/auth.service';
import { CookiesPopupComponent } from './shared/ui/cookies-popup/cookies-popup.component';

const COOKIE_VISIBLE_KEY = 'isCookiesVisible';

@Component({
  selector: 'coach-root',
  standalone: true,
  imports: [
    RouterOutlet,
    NavigationComponent,
    TopBarComponent,
    CookiesPopupComponent,
    MatSidenavModule,
    NgClass,
    NgStyle,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  auth = inject(AuthService);
  device = inject(DeviceService);
  isCookiesVisible = signal<boolean>(true);

  constructor() {
    const isCookiesVisibleStorage = localStorage.getItem(COOKIE_VISIBLE_KEY);
    if (isCookiesVisibleStorage !== null) {
      this.isCookiesVisible.set(JSON.parse(isCookiesVisibleStorage));
    }
    effect(() => {
      localStorage.setItem(COOKIE_VISIBLE_KEY, JSON.stringify(this.isCookiesVisible()));
    });
  }
}
