import { Component, model } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonComponent } from '@shared/ui/button/button.component';

@Component({
  selector: 'coach-cookies-popup',
  standalone: true,
  imports: [TranslocoDirective, ButtonComponent],
  templateUrl: './cookies-popup.component.html',
  styleUrl: './cookies-popup.component.scss',
})
export class CookiesPopupComponent {
  isVisible = model<boolean>(true);

  closePopover() {
    this.isVisible.set(false);
  }
}
