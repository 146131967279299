import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideAuth } from '@shared/providers/auth.provider';
import { provideErrorHandler } from '@shared/providers/error-handler.provider';
import { provideI18n } from '@shared/providers/i18n.provider';
import { ROUTES } from './app.routes';
import { authInterceptor } from './auth/interceptors/auth.interceptor';
import { provideFeatureFlag } from './shared/providers/feature-flag.provider';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(ROUTES, withComponentInputBinding()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideErrorHandler(),
    provideHttpClient(withFetch(), withInterceptors([authInterceptor])),
    provideAuth(),
    provideFeatureFlag(),
    provideI18n(isDevMode()),
    provideAnimationsAsync(),
  ],
};
