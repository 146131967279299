<div class="menu-container" *transloco="let t">
  <img src="assets/images/cookie-icon.svg" alt="cookies icon" />
  <div class="text-section-container">
    <div class="title">{{ t('cookies.title') }}</div>
    <div class="text">
      <span>{{ t('cookies.text.part1') }}</span>
      <a class="link" href="">{{ t('cookies.cookiePolicy') }}</a>
      <span>{{ t('cookies.text.part2') }}</span>
      <a class="link" href="">{{ t('cookies.profileSettings') }}</a>
      <span>{{ t('cookies.text.part3') }}</span>
      <a class="link" href="">{{ t('cookies.privacyPolicy') }}</a>
    </div>
    <div class="button-container">
      <coach-button (click)="closePopover()" size="large" type="primary" [text]="t('cookies.acceptAllCookies')" />
      <coach-button
        (click)="closePopover()"
        size="large"
        type="info"
        variant="outlined"
        [text]="t('cookies.acceptEssentialCookies')" />
    </div>
  </div>
</div>
