import { Directive, inject, Injector, OnDestroy, OnInit } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
  UntypedFormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputControlDirective,
      multi: true,
    },
  ],
})
export class InputControlDirective implements ControlValueAccessor, OnInit, OnDestroy {
  control!: FormControl;

  private injector = inject(Injector);
  private subscription?: Subscription;

  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl, null, { self: true, optional: true });

    if (ngControl instanceof FormControlName) {
      const group = this.injector.get(ControlContainer).control as UntypedFormGroup;
      this.control = group.controls[ngControl.name!] as FormControl;
      return;
    }

    if (ngControl instanceof FormControlDirective) {
      this.control = ngControl.control;
      return;
    }

    if (ngControl instanceof NgModel) {
      this.subscription = ngControl.control.valueChanges.subscribe(value => {
        if (ngControl.model !== value || ngControl.viewModel !== value) {
          ngControl.viewToModelUpdate(value);
        }
      });
      this.control = ngControl.control;
      return;
    }

    // Fallback
    this.control = new FormControl();
  }

  writeValue(): void {}
  registerOnChange(): void {}
  registerOnTouched(): void {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
