<mat-drawer-container #sidenav class="drawer-container">
  @if (auth.isLoggedIn()) {
    <mat-drawer
      class="drawer-nav"
      [ngClass]="{ mobile: device.isTablet() }"
      [mode]="device.isTablet() ? 'over' : 'side'"
      (closedStart)="device.setSideNavState(false)"
      [opened]="device.isSideNavOpen() && !auth.isOnlyRoles(['player'])">
      <coach-navigation />
    </mat-drawer>
  }
  <mat-drawer-content class="drawer-content">
    <main class="container" [class.padded]="auth.isLoggedIn()">
      @if (auth.isLoggedIn()) {
        <coach-top-bar />
        @if (isCookiesVisible()) {
          <coach-cookies-popup [(isVisible)]="isCookiesVisible" />
        }
      }
      <router-outlet />
    </main>
  </mat-drawer-content>
</mat-drawer-container>
