import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const adminGuard: CanMatchFn = (route, state) => {
  const auth = inject(AuthService);

  const router = inject(Router);

  if (!auth.isLoggedIn()) {
    router.navigate(['/login'], { queryParams: { redirect: `/${state.map(segment => segment.path).join('/')}` } });
    return false;
  }

  if (auth.isAdmin()) {
    return true;
  }

  return false;
};
