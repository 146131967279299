import { Component, inject } from '@angular/core';
import { DeviceService } from '@shared/services/device.service';
import { IconComponent } from '@shared/ui/icon/icon.component';
import { Tab } from '@shared/ui/tabs/tab/tab.component';
import { TabsComponent } from '@shared/ui/tabs/tabs.component';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ButtonComponent } from '../../shared/ui/button/button.component';

@Component({
  selector: 'coach-navigation',
  standalone: true,
  imports: [TabsComponent, ButtonComponent, IconComponent],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
})
export class NavigationComponent {
  #auth = inject(AuthService);
  device = inject(DeviceService);

  tabs: Tab[] = [
    { id: 'dashboard', path: 'dashboard', i18n: 'navigation.home', icon: 'house_outlined' },
    { id: 'players', path: 'players', i18n: 'navigation.player', icon: 'profile_rounded' },
    { id: 'assignment', path: 'assignment', i18n: 'navigation.assignment', icon: 'coach_assignments' },
    { id: 'drill', path: 'drill', i18n: 'navigation.drill', icon: 'coach_custom_drill' },
    { id: 'admin', path: 'admin', i18n: 'navigation.admin', icon: 'lock', visible: this.#auth.isAdmin() },
  ];

  tabClicked() {
    this.device.setSideNavState(false);
  }
}
