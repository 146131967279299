import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { ToastService } from '@shared/services/toast.service';
import { ButtonComponent } from '@shared/ui/button/button.component';
import { LanguagePickerComponent } from '@shared/ui/language-picker/language-picker.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'coach-invite',
  standalone: true,
  imports: [ButtonComponent, LanguagePickerComponent, TranslocoDirective],
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss', '../login/login.component.scss'],
})
export class InviteComponent {
  #router = inject(Router);
  #route = inject(ActivatedRoute);
  #auth = inject(AuthService);
  #toast = inject(ToastService);

  returnUrl = this.#router.url;

  constructor() {
    const error = this.#route.snapshot.queryParams['error'];
    if (error) {
      this.#toast.openToast({ type: 'negative', title: 'Error', description: error });
    }
  }

  logIn() {
    this.#auth.logIn(this.returnUrl);
  }
}
