import { Routes } from '@angular/router';
import { DashboardComponent } from '@core/dashboard/dashboard.component';
import { PlayerListComponent } from '@features/player/player-list/player-list.component';
import { NotFoundComponent } from '@shared/ui/not-found/not-found.component';
import { adminGuard } from './auth/guards/admin.guard';
import { authGuard } from './auth/guards/auth.guard';
import { inviteGuard } from './auth/guards/invite.guard';
import { InviteComponent } from './auth/ui/invite/invite.component';
import { LoginCallbackComponent } from './auth/ui/login-callback/login-callback.component';
import { LoginComponent } from './auth/ui/login/login.component';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login-callback',
    component: LoginCallbackComponent,
  },
  {
    path: 'invite/:token',
    component: InviteComponent,
    canActivate: [inviteGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [authGuard],
  },
  {
    path: 'players',
    component: PlayerListComponent,
    canActivate: [authGuard],
  },
  {
    path: 'player',
    loadChildren: () => import('./features/player/player.routes').then(m => m.PLAYER_ROUTES),
    canActivate: [authGuard],
  },
  {
    path: 'assignment',
    loadChildren: () => import('./features/assignment/assignment.routes').then(m => m.ASSIGNMENT_ROUTES),
    canActivate: [authGuard],
  },
  {
    path: 'drill',
    loadChildren: () => import('./features/drill/drill.routes').then(m => m.DRILL_ROUTES),
    canActivate: [authGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./features/settings/settings.routes').then(m => m.SETTINGS_ROUTES),
    canActivate: [authGuard],
  },
  {
    path: 'support',
    loadChildren: () => import('./features/support/support.routes').then(m => m.SUPPORT_ROUTES),
    canActivate: [authGuard],
  },
  {
    // TODO: Add backoffice routes
    path: 'admin',
    loadChildren: () => import('./features/support/support.routes').then(m => m.SUPPORT_ROUTES),
    canActivate: [authGuard],
    canMatch: [adminGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
