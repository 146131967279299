<coach-dialog *transloco="let t" [title]="t('playerInvite.invitePlayer')">
  <div class="input">
    <form [formGroup]="form">
      <coach-input [label]="t('email')" formControlName="email" [errorMessage]="t('error.form.email')" />
      <coach-input
        [label]="t('playerInvite.fullName')"
        formControlName="fullName"
        [errorMessage]="t('error.form.required')" />
    </form>
  </div>
  <coach-button (click)="dialogRef.close('close')" variant="text" type="primary" ngProjectAs="left-button">
    {{ t('cancel') }}
  </coach-button>

  <coach-button
    (click)="sendInvite()"
    [loading]="isTryingToInvite()"
    [disabled]="!isFormValid()"
    [text]="t('playerInvite.sendInvite')"
    ngProjectAs="right-button" />
</coach-dialog>
