<div class="input-wrapper">
  <div class="input-field">
    <input
      type="text"
      [formControl]="inputControl.control"
      [placeholder]="placeholder()"
      [class]="size()"
      [ngClass]="{
        error: inputControl.control.touched && !inputControl.control.valid,
        disable: inputControl.control.disabled,
      }" />

    @if (label().length) {
      <label
        [class]="size()"
        [ngClass]="{
          error: inputControl.control.touched && !inputControl.control.valid,
          disable: inputControl.control.disabled,
        }"
        for="label">
        {{ label() }}
      </label>
    }
  </div>
  @if (errorMessage().length && inputControl.control.touched && !inputControl.control.valid) {
    <p class="error-message">{{ errorMessage() }}</p>
  }
</div>
