import { Dialog } from '@angular/cdk/dialog';
import { CdkTableModule, DataSource } from '@angular/cdk/table';
import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { Component, computed, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { ApiService } from '@shared/services/api.service';
import { DeviceService } from '@shared/services/device.service';
import { PlayerService } from '@shared/services/player.service';
import { ToastService } from '@shared/services/toast.service';
import { valueOf, viewModelFrom } from '@shared/types/api.types';
import { CloseDialogStatus } from '@shared/types/generic.types';
import { PlayerBase, PlayerInvite } from '@shared/types/user.types';
import { AvatarComponent } from '@shared/ui/avatar/avatar.component';
import { BadgeComponent } from '@shared/ui/badge/badge.component';
import { ButtonComponent } from '@shared/ui/button/button.component';
import { EmptyStateComponent } from '@shared/ui/empty-state/empty-state.component';
import { SearchComponent } from '@shared/ui/search/search.component';
import { SpinnerComponent } from '@shared/ui/spinner/spinner.component';
import { Tab } from '@shared/ui/tabs/tab/tab.component';
import { TabsComponent } from '@shared/ui/tabs/tabs.component';
import { Observable, ReplaySubject } from 'rxjs';
import { ActiveBadgeComponent } from '../../../shared/ui/active-badge/active-badge.component';
import { InviteDialogComponent } from '../invite-dialog/invite-dialog.component';

const LINKED_COLUMNS = ['name', 'activity'];
const INVITED_COLUMNS = ['name', 'status', 'expiration_date', 'actions'];

@Component({
  selector: 'coach-player-list',
  standalone: true,
  host: { class: 'main-container' },
  imports: [
    AvatarComponent,
    TranslocoDirective,
    SearchComponent,
    ButtonComponent,
    TabsComponent,
    CdkTableModule,
    RouterLink,
    DatePipe,
    EmptyStateComponent,
    SpinnerComponent,
    NgTemplateOutlet,
    BadgeComponent,
    ActiveBadgeComponent,
  ],
  templateUrl: './player-list.component.html',
  styleUrl: './player-list.component.scss',
})
export class PlayerListComponent {
  api = inject(ApiService);
  dialog = inject(Dialog);
  toast = inject(ToastService);
  transloco = inject(TranslocoService);
  playerService = inject(PlayerService);
  device = inject(DeviceService);

  filterQuery = signal<string>('');
  data$ = viewModelFrom(this.playerService.playerList$);
  valueOfData = toSignal(valueOf(this.data$));
  data = toSignal(this.data$);

  invitedColumns = computed(() => {
    if (this.device.isMobile()) {
      return ['name', 'actions'];
    }
    if (this.device.isTablet()) {
      return ['name', 'status', 'actions'];
    }
    return INVITED_COLUMNS;
  });

  currentTabId = signal<'linked' | 'invited'>('linked');
  state = computed(() => {
    const data = this.valueOfData();
    const onLinkedTab = this.currentTabId() === 'linked';
    const numberOfInvites = data?.invited.length || 0;

    return {
      data: this.data(),
      tabs: signal<Tab[]>([
        {
          id: 'linked',
          i18n: 'players.linked',
          count: { value: data?.linked.length || 0 },
        },
        {
          id: 'invited',
          i18n: 'invite.invited',
          visible: numberOfInvites > 0,
          count: { value: numberOfInvites },
        },
      ]),
      onLinkedTab: onLinkedTab,
      isCurrentTabEmpty: data?.[this.currentTabId()].length === 0,
      source: new PlayerDataSource(onLinkedTab ? data?.linked : data?.invited),
      displayedColumns: onLinkedTab ? LINKED_COLUMNS : this.invitedColumns(),
    };
  });

  constructor() {
    effect(() => {
      this.playerService.filterPlayerList(this.filterQuery());
    });
  }

  openInviteDialog(name?: string, email?: string, isResend = false) {
    const dialogRef = this.dialog.open<CloseDialogStatus>(InviteDialogComponent, { data: { name, email, isResend } });

    dialogRef.closed.subscribe(status => {
      if (status === 'success') {
        this.playerService.refreshPlayerList();
        this.toast.openToast({
          type: 'positive',
          title: this.transloco.translateObject('playerInvite.playerInvited'),
          description: this.transloco.translateObject('playerInvite.invitationDescription'),
        });
      } else if (status === 'error') {
        this.toast.openToast({
          type: 'negative',
          title: 'An error occured',
          description: 'Please try again later',
        });
      }
    });
  }
}

type Player = PlayerBase | PlayerInvite;

export class PlayerDataSource extends DataSource<Player> {
  #dataStream = new ReplaySubject<Player[]>();

  constructor(initialData: Player[] | undefined) {
    super();
    this.setData(initialData ?? []);
  }

  connect(): Observable<Player[]> {
    return this.#dataStream;
  }

  disconnect() {}

  setData(data: Player[]) {
    this.#dataStream.next(data);
  }
}
