import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject } from '@angular/core';
import { ToastService } from '@shared/services/toast.service';
import { ErrorResponse } from 'oidc-client-ts';

class CoachErrorHandler extends ErrorHandler {
  toast = inject(ToastService);

  override handleError(error: HttpErrorResponse | ErrorResponse): void {
    // Left here to track down potential causes for errors
    // eslint-disable-next-line no-console
    console.error('Error handler:', error);
    this.toast.openToast({
      type: 'negative',
      title: 'An error occurred',
      description: error.error ?? error.message ?? 'Unknown error',
    });
  }
}

export const provideErrorHandler = () => ({
  provide: ErrorHandler,
  useClass: CoachErrorHandler,
});
